import { Component, OnInit, Input } from '@angular/core';
import { PreinscripcionDetail } from '../models/Preinscripcion';
import { PreinscripcionesService } from '../servicios/preinscripciones.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Registro } from '../models/Registros';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  @Input() edicion: boolean = false;
  @Input() admin: boolean = false;
  @Input() preinscripcion = {} as PreinscripcionDetail;
  @Input() reg = {} as Registro;

  constructor(private preinscripcionService: PreinscripcionesService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getPreinscripcionInfo();
  }

  getPreinscripcionInfo(): void {
    this.spinner.show();
    this.preinscripcionService.getUltimoPeriodoInfo().subscribe(resp => {
      this.preinscripcion = resp.preinscripcion;
      this.spinner.hide();
    });
  }

  esExtrangero() {
    if (this.reg.extranjero == "1") {
      Swal.fire("", "Se te generara una curp temporal, es importante que la guardes para modificar tus datos posteriormente en caso de error.", "info")
      this.reg.curp = "XXXX" + this.makeRandomNumber(6) + this.makeRandomText(6) + "XX";
    }
  }

  makeRandomNumber(length: number) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  makeRandomText(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  RegistrarSolicitud(f: NgForm) {
    if (!f.valid) {
      Swal.fire(
        'Preinscripciones',
        "Verifique que todos los campos marcados con * esten correctamente llenos.",
        'warning'
      );
      return;
    }

    if (!this.curpValida(this.reg.curp) && this.reg.extranjero != "1") {
      Swal.fire(
        'Preinscripciones',
        "La curp no es valida, verifiquela.",
        'warning'
      );
      return;
    }

    if (!this.validarEmail(this.reg.correo)) {
      Swal.fire(
        'Preinscripciones',
        "El correo personal no es valido, verifiquelo.",
        'warning'
      );
      return;
    }

    if (!this.validarTelefono(this.reg.telefono_celular)) {
      Swal.fire(
        'Preinscripciones',
        "El telefono celular debe de ser de 10 digitos.",
        'warning'
      );
      return;
    }

    if (!this.validarTelefono(this.reg.telefono1)) {
      Swal.fire(
        'Preinscripciones',
        "El telefono del tutor 1 debe de ser de 10 digitos.",
        'warning'
      );
      return;
    }

    if (!this.validarTelefono(this.reg.telefono2)) {
      Swal.fire(
        'Preinscripciones',
        "El telefono del tutor 2 debe de ser de 10 digitos.",
        'warning'
      );
      return;
    }

    if (!this.validarTelefono(this.reg.telefono3)) {
      Swal.fire(
        'Preinscripciones',
        "El telefono del tutor 3 debe de ser de 10 digitos.",
        'warning'
      );
      return;
    }

    //FechaNac
    if(!this.validarNacimiento(this.reg.fechaNac)){
      Swal.fire(
        'Preinscripciones',
        "Verifique su año de nacimiento, la edad debe ser entre 13 a 19 años.",
        'warning'
      );
      return;
    }

    //Validar No repetidas
    var especialidad = [];
    var duplicados = [] as string[];
    especialidad.push(this.reg.carrera1);
    especialidad.push(this.reg.carrera2);
    especialidad.push(this.reg.carrera3);

    for (let i = 0; i < especialidad.length; i++) {
      if (!duplicados.includes(especialidad[i])) {
        duplicados.push(especialidad[i]);
      }
    }

    if (duplicados.length < 3) {
      Swal.fire(
        'Preinscripciones',
        "En el apartado opciones de carrera, tus tres opciones deben de ser diferentes, verifica por favor.",
        'warning'
      );
      return;
    }

    var params = this.reg;

    this.spinner.show();

    if (!this.edicion) {
      params.idPreincripcion = this.preinscripcion.id;

      this.preinscripcionService.postNuevaSolicitudPreinscripcion(params).subscribe(response => {
        this.spinner.hide();
        if (response.success) {

          Swal.fire({
            title: 'Preinscripciones',
            text: "Registro realizado exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar'
          }).then((result) => {
            this.router.navigate(['/imprimir', { id: response.id }]);
          });

        } else {
          Swal.fire(
            'Preinscripciones',
            response.message,
            'warning'
          );
        }

      });
    } else {
      this.preinscripcionService.postActualizarSolicitudPreinscripcion(params).subscribe(response => {
        this.spinner.hide();
        if (response.success) {
          params.idPreincripcion = params.idInscripcion;
          Swal.fire({
            title: 'Preinscripciones',
            text: "Registro actualizado exitosamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar'
          }).then((result) => {
            this.router.navigate(['/imprimir', { id: params.id }]);
          });

        } else {
          Swal.fire(
            'Preinscripciones',
            response.message,
            'warning'
          );
        }

      });
    }

  }

  curpValida = function (curp: string) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
      return false;

    return true; //Validado
  }

  validarTelefono(telefono: string) {
    if (telefono.toString().length == 10) {
      return true;
    } else {
      return false;
    }
  }

  validarEmail(valor: string) {
    var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(valor) ? true : false;
  }

  validarNacimiento(fecha:string){
    var nac = new Date(fecha).getFullYear();
    var actual = new Date().getFullYear();

    var diff = actual - nac;
    if(diff>13 && diff<20){
      return true;
    }else{
      return false;
    }
  }

  validarDigitos(event:any) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }

    validarPromedio(){
      if(Number(this.reg.promedio) > 10){
        Swal.fire(
          'Preinscripciones',
          "El promedio no puede ser mayor de 10",
          'warning'
        );
        this.reg.promedio = "10";
       }
    }

}
